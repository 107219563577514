/* src/AboutUs.css */

.about-us-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  line-height: 1.6;
}

.about-us-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

.about-us-container h2 {
  font-size: 1.5em;
  margin-top: 30px;
  margin-bottom: 10px;
}

.about-us-container p {
  margin-bottom: 20px;
  color: #333;
}

/* General Mobile Styles (up to 768px) */
@media (max-width: 768px) {
.about-us-container {
  margin: 20px 10px;
  padding: 15px;
  margin-bottom: 240px;
  margin-top: 600px; /* Default for most mobile devices */
}

.about-us-container h1 {
  font-size: 1.5em;
}

.about-us-container h2 {
  font-size: 1.2em;
}

.about-us-container p {
  font-size: 0.9em;
}
}

/* Specific Styles for Mini Mobiles (up to 650px) */
@media (max-width: 650px) {
.about-us-container {
  margin-top: 800px; /* Larger margin for smaller devices */
}
}

/* Specific Styles for Larger Mobiles (651px to 768px) */
@media (min-width: 651px) and (max-width: 768px) {
.about-us-container {
  margin-top: 600px; /* Ensure this margin-top for larger devices */
}
}
